// $base: rgb(67,117,155);
// $base: rgb(40,125,125);
// $base: rgb(120,0,230);
// $base: rgb(150,200,255);
// $secondary: rgb(150,200,255);
// $secondary: rgb(150,200,255);
// $secondary: rgb(50,150,150);
$radius: 10px;
$primary: #9ebcdd;
$primaryLight: #dfe9f5;
$primaryDark: #6393C7;
$secondary: #282d37;
$secondaryLight: rgb(30,33,40);
// $secondaryLight: rgb(96,106,130);

html, body, #root, #root>div {
	@extend .primary;
	height:100%;
}

.primary {
	background-color: $primary;
	border-color: $secondary !important;
	color: $secondary;
	&>a, &>a:visited {color: $secondary;}
}

.secondary {
	background-color: $secondary;
	border-color: $primary !important;
	color: $primaryLight;
	&>a, &>a:visited {color: $primary;}
}

.primaryLight {
	@extend .primary;
	background-color: $primaryLight;
}

.darkmode {
	.secondary {
		background-color: $primary;
		border-color: $secondary !important;
		color: $secondary;
		a, a:visited {color: $secondary;}
	}

	.primary {
		background-color: $secondary;
		border-color: $primary !important;
		color: $primary;
		a, a:visited {color: $primary;}
	}

	.primaryLight {
		@extend .primary;
		background-color: $secondaryLight;
	}

}

#main {
	// background-color: #aaa6;
	flex-grow: 1;
	overflow: auto;
	&>div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: start;
	}
}

#header {
	@extend .secondary;
	// height: 40px;
	// padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 3px solid black;
	&>div {@extend .secondary;}
}

#headerCenter {
	font-size: 52px;
	@media (max-width: 1100px) { font-size: 4.8vw; }
}

#headerLeft, #headerRight {
	width: 25vw;
	@media (max-width: 1000px) { font-size: 2.4vw; }
	font-size: 26px;
	height:100%;
	display: flex;
	.indicator {
		font-size: 14px;
		background-color: red;
		width:20px;
		height:20px;
		//padding-top: 2px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		//margin-top: 0px;
	}
}

#headerRight {
	justify-content: flex-end;
	align-items: center;
}

#dialog > .search {
	@extend .primary;
	textarea {
		border-radius: 5px;
	}
}

#footer {
	@extend .secondary;
	padding:5px;
	text-align: center;
	font-size: 16px;
}


#register table {
	input, select {
		&:not([type=text]):not([type=password]) {
			width: 145px;
			box-sizing: border-box;
		}
	}
}

.btn {
	color: $primaryLight;
	border: none;
	background-color: $secondary;
	border-radius: 5px;

	cursor: pointer;
	&:hover {
		//color: yellow;
		background-color: rgb(99,116,138);
	}
	&:active {
		background-color: rgb(134,159,188) !important;
	}
	&:disabled,&.disabled,&.disabled:hover {
		background: linear-gradient(to left, gray, lightgray) !important;
		cursor: not-allowed;
		color: black;
	}
}

h2 .btn {
	height:100%;
	border-left: 1px solid $primary;
	border-radius: 0px 10px 0px 0px;
	padding: 10px 20px;
	display: flex;
	align-items: center;
}

#header .btn {
	padding: 0px 10px;
	border-radius: 0px;
}

#headerRight .btn {
	border-left: 1px solid $primary;
}

#headerLeft .btn {
	border-right: 1px solid $primary;
}



#searches {
	table {
		border-collapse: collapse !important;
		td,th {
			text-align: center;
			padding-left: 10px;
			padding-right: 10px;
		}

		td > button {
			width: 30px;
			height: 30px;
			border-radius: 10px;
			padding: 0px;
			line-height: 0px;
		}
	}
}

a {
	color: unset;
}

.container {
	@extend .primary;
	border-radius: $radius;
	border: 1px solid black;
	display:flex;
	flex-direction: column;
	min-width: 250px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	h2 {
		@extend .secondary;
		height: 48px;
		margin: 0px;
		color: rgba(223,233,245,1);
		border-radius: $radius $radius 0px 0px;
		display: flex;
		justify-content: space-between;
		span {
			margin: 10px 20px;
		}
	}
	.filler {
		@extend .primaryLight;
		border-radius: 0px 0px $radius $radius;
		flex-grow: 1;
		padding-bottom: $radius;
		overflow-y: auto;
		padding: 0px 15px;
	}
	input:not([type=checkbox]):not([type="radio"]), select {
		width:160px;
		border: 1px solid black;
		padding: 1px 2px;
		box-sizing: content-box;
		margin: 5px;
	}

	&.folded {
		border: none;
		.filler {display: none !important; }
	}
}

.flexContainer {
	display:flex;
	align-items: center;
	&.stretch {
		align-items: stretch !important;
	}
	@media (max-width: 1000px) {
		flex-direction: column;
		align-items: center !important;
		width: unset !important;
		&>div {
			margin-left:0px !important;
			margin-right:0px !important;
			width: unset !important
		}
	}
	//table {width: 300px}
}

#profile, #user { .flexContainer {
	@media (min-width: 1000px) {
		height: 400px
	}
	align-items: stretch !important;
	margin-top: 50px;
	&>div {
		max-width: 90vw;
		overflow-x: auto;
	}
}}

#profile {
	width: 1200px;
	margin: 0px auto;
	@media (max-width: 1000px) {
		width: 90vw;
	}
}

#userSettings, #userInfo {
	@media (max-width: 1000px) {
		margin: 50px 0px;
	}
}

.seperator {
	background-color: black;
	margin: 50px;
	width: 3px;
	height: calc(100% - 20px);
	@media (max-width: 1000px) {
		height: 5px;
		width: calc(100% - 20px);
	}
}

table {
	input, select {
		margin: 10px 0px 10px 10px;
	}
}


.changed {
	background-color: cyan;
}


#regions > div {
	display: flex;
	flex-direction: column;
	width: 285px;
	select {
		margin-bottom: 10px;
	}
}

#searchResults {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	width: 100%;
	a {
		color: black;
		text-decoration: none;
	}
}

.searchResult {
	width: calc(min(800px, 80vw));
	height: 330px;
	margin: 20px;

	.filler {
		padding: 5px 15px;
		& > div {
			display: inline-block;
		}
}
	&.marked {
		box-shadow: 0px 0px 15px 15px #fff8;
	}
}



.searchResultInfo {
	vertical-align: top;
	max-width: 300px;
	//margin-left: 30px;
	display: inline-flex !important;
	flex-direction: column;
	justify-content: start;
	span {
		margin-bottom: 10px;
	}
}

.dashedLine {
	height:1px;
	width:100%;
	border-top: 1px dashed $primary;
	margin: 10px 0px;
}

.regionSelector {
	margin-top: 5px !important;
	margin-left: 20px !important;
	width: 250px !important;
}

.stateSelector {
	margin-top: 20px !important;
	width: 270px !important;
}

.searchBtn {
	width:calc(max(80vw, 500px));
	height:20px;
	padding:0px;
}

#userInfo {
	td {
		padding-top: 10px;
	}
}

#contacts {
	height: calc(100% - 40px);
	border-radius: 0px 10px 10px 0px;
	h2 { border-radius: 0px 10px 0px 0px; }
	.filler { border-radius: 0px 0px 10px 0px; }

	& .filler>div {
		border-top: 1px solid $primary;
		border-bottom: 1px solid $primary;
		cursor: pointer;
		padding: 15px;

		&.unseen {font-weight:bold}
		&.selected {
			background-color: $primary;
		}
	}
	span { font-size: 28px; }
}

xmp {
	margin: 0px;
	font-family: "Arial";
	white-space: break-spaces;
}

#contactContainer {
	flex-grow: 1;
	height: calc(100% - 40px);
	margin-left: 20px;
	margin-right: 20px;
	//border-left:1px solid black;
	display: flex;
	flex-direction: column;
	button {
		align-self: center;
		font-size: 20px;
		margin-top: 5px
	}
}

#messages {
	flex-grow: 1;
	display: flex;
	flex-direction: column-reverse;
	span {
		margin: 5px;
		padding: 15px;
		border-radius: 15px;
		max-width: calc(100% - 100px)
	}
	.sent {
		background-color: $primaryDark;
		align-self: flex-end;
	}
	.received {
		@extend .primary;
		align-self: flex-start;

	}

	a {
		text-align: center;
	}
}

.profilePic {
	border-radius: $radius;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.__react_component_tooltip {
	overflow-y: hidden !important;
	padding: 10px !important;
}

#searchButtonRow {
	width: 1320px;
	background: $secondary;
	display:flex;
	color: $primaryLight;
	justify-content: space-between;
	font-size: 30px;
	height: 70px;
	margin: 20px 0px;
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	input[type=checkbox] {
		width:20px;
		height:20px;
	}
	.btn {
		font-size: 30px;
		width: 300px;
		border-right: 1px solid $primaryLight;
	}
	&> div {
		width: 360px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 1000px) {
		width:600px;
		font-size: 16px;
		.btn {
			font-size: 16px;
			width: 150px;
			img {
				margin-right: 10px !important;
			}
		}
	}
}
